import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./blog-post.scss";
import { useLocation } from "react-router-dom";
import MiniBlog from "./MiniBlog";
import { useParams } from "react-router";
import { blogPostColOne } from "../../../TestData";
import { getItems } from "../../../core/QueryResponseProvider";
import { Heading } from "../../../components/Heading/Heading";
import Loader from "../../../components/Loader/Loader";



function MainBlog() {
  // const { postSlug } = useParams();
  const params = useParams();
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [posts, setPosts] = useState([]);
  const location = useLocation();



  const getPostData = () => {
    setLoading(true);
    getItems('auth/v3/blog/' + params.postSlug).then((resp) => {
      setData(resp.post);
      setPosts(resp.related_posts);
      setLoading(false);
    }, (resp) => {
      setLoading(false);
    })
  };


  const getSingleBLog = () => {

  }



  useEffect(() => {
    getPostData();
  }, [params.postSlug]);


  const handleSocialMediaShare = (platform) => {
    return;
    const shareURL = encodeURIComponent(window.location.href);
    const shareText = encodeURIComponent(data.title);

    let shareLink;

    switch (platform) {
      case "twitter":
        shareLink = `https://twitter.com/intent/tweet?url=${shareURL}&text=${shareText}`;
        break;
      case "facebook":
        shareLink = `https://www.facebook.com/sharer/sharer.php?u=${shareURL}`;

        break;
      case "linkedin":
        shareLink = `https://www.linkedin.com/shareArticle?mini=true&url=${shareURL}&title=${shareText}`;

        break;
      default:
        return;
    }

    window.open(shareLink, '_blank', 'width=600,height=400');
  };
  return (
    <div className="blogs">
      {/*<div className="social-media-container">*/}
      {/*  <div className="social-media-box facebook" onClick={() => handleSocialMediaShare("facebook")}></div>*/}
      {/*  <div className="social-media-box twitter" onClick={() => handleSocialMediaShare("twitter")}></div>*/}
      {/*  <div className="social-media-box instagram" onClick={() => handleSocialMediaShare("linkedin")}></div>*/}
      {/*</div>*/}
      <div className="back">

        <Heading text="< Back"
          address={"/blog"}
          bg="#CEF0FF"
          color="#0098DA"
          boda="#CEF0FF" />
      </div>

      {isLoading ? (
          <Loader />
      ) : (data ? <>
        <div className=" blog-container">
          <div className="col-md-10 head-area">


            <div className="blog-header">
              <div className='title'>
                <h1 className="main-blog-heading ">{data.title}</h1>
                <div>
                  <p></p>
                  <p className=''>posted on {data.formattedPostDate} in <span className="category">{data.category_name}</span></p>
                </div>
              </div>
              <div className="publisher">
                {data?.thumbnail && <div className="avi-cover">
                  {" "}
                  <img
                    className="avi"
                    src={data.publishing_picture}
                    alt="avi"
                  />
                </div>}
                <div className="linkedin">
                  <label>post by </label>
                  <div className="author">
                    {data.linkedin?(<a href={data.linkedin} target="_blank">{data.posted_by}</a>):
                        (data.posted_by)}
                  </div>

                </div>

              </div>
            </div>
            <div className="head-social-media-divider">
              <hr className="divider" />
              {/*<div className="social-media-box-divider facebook1" onClick={() => handleSocialMediaShare("facebook")}></div>*/}
              {/*<div className="social-media-box-divider twitter1" onClick={() => handleSocialMediaShare("twitter")}></div>*/}
              {/*<div className="social-media-box-divider linkedin1" onClick={() => handleSocialMediaShare("linkedin")}></div>*/}
              <hr className="divider" />
            </div>
          </div>
        </div>


        <div className="blog-post-container  reading col-md-12 ">
          {data?.thumbnail && <div className="col-md-10  text-center header" >
            {" "}
            <img src={data?.thumbnail} alt="blog header" />
          </div>}

          <div className="col-md-10 text-left mt5 blog-summary" dangerouslySetInnerHTML={{ __html: data?.body }}>
          </div>
        </div>

        {posts?.length > 0 && <div className="col-md-10 offset-md-1 mt">
          <h3>More from the Blog</h3>
          <p>
            The latest news, interviews, technologies, and resources on educare.
          </p>
          <div className="col-md-12 latest-news gap-5">
            {" "}
            {posts?.map((post) => (
              <MiniBlog data={post} key={post.slug} />
            ))}
          </div>
          <div className="text-center">
            <Link to={"/blog"}>
              <button className="resos-btn text-center">View all Posts</button>
            </Link>
          </div>

        </div>}
      </>: (
          <>
            <div className=" blog-container">
              <div className="col-md-10 head-area">


                <div className="blog-header">
                  <div className='title'>
                    <h1 className="main-blog-heading "></h1>
                    <div>
                      <p></p>
                      <p className=''></p>
                    </div>
                  </div>
                  <div className="publisher">

                    <div className="linkedin">


                    </div>

                  </div>
                </div>
              </div>
            </div>
            <div className="blog-post-container  reading col-md-12 ">
              <div className="col-md-10 text-center mt5 mb5 blog-summary">
                  <h2>404 - PAGE NOT FOUND</h2>
                  <div className="mt1">The page you are looking for might have been removed,<br/>
                    had its name changed or is temporarily unavailable.
                  </div>
                <div className="back-btn mt1 mb-5">

                  <Heading text="Back to Blog Home"
                           address={"/blog"}
                           bg="#2f99da"
                           color="#FFFFFF"
                           boda="#CEF0FF" />
                </div>
              </div>
            </div>
          </>
      ))}
    </div>
  );
};

export default MainBlog;
