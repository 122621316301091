import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Arrow from "../../assets/images/link-arrow.svg";
import Img3 from "../../assets/images/ln1.png";

function LatestBlog({ data }) {
  const [blogId, setBlogId] = useState(0);
  useEffect(() => {
    setBlogId(data.blog_id);
  });
  return (
    <>
      <div className="latest-post-container col-md-12 ">
        <Link to={'/blog/' + data.slug} state={{ blog_id: blogId }}>
          <div className="text-center" style={{ backgroundImage: 'url(' + (data.thumbnail ? data.thumbnail : Img3) + ')', backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', height: '200px', borderRadius: '20px 20px 0 0' }}>
            {/*<img className="" src={data.thumbnail?data.thumbnail: Img3} alt={data.title} height="200" />*/}
          </div>
        </Link>
        <Link to={'/blog/' + data.slug} state={{ blog_id: blogId }} className="blog-post-container-inner min">
          <p className="">{data.formattedPostDate}</p>
          <h5>{data.title}</h5>
          <p>{data.displayBody}</p>
          <div className="flexy">
            <img className="bloger-img" src={data.publishing_picture} alt="thumbnail" />
            <div>
              <h6>{data.posted_by}</h6>
              <p className="bloger">{data.position} </p>
            </div>
            <div className="readtime">
              <small>{data.estimated_time} Minutes Read</small>
            </div>
          </div>
        </Link>
  
      </div>
    </>
  );
}

export default LatestBlog;
