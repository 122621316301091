import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./blog-post.scss";
import Arrow from "../../../assets/images/link-arrow.svg";
import Img3 from "../../../assets/images/ln1.png";

function MiniBlog({ data }) {
  return (
    <>
      {data &&
        <div className="blog-post-container-outer blog-width ">
          <div className="blog-post-container">
            <Link to={'/blog/' + data.slug} className="heder-img">
              <img className="img" src={data.thumbnail} alt="thumbnail" />
            </Link>
            <div className="blog-post-container-inner">
              <h5>{data.title}</h5>
              <p>{data.displayBody}</p>
              <div className="flexy flexyM blog-footer">
                <Link
                  to={'/blog/' + data.slug}
                  state={{ blog_id: data.slug }}
                  className="blog-link">
                  Read post{" "}
                </Link>
                <p className="mt3">{data.formattedPostDate}</p>
              </div>
            </div>

          </div>

        </div>}
    </>
  );
}

export default MiniBlog;
