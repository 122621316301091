import React, { useEffect, useState } from "react";
import "./blog.scss";
import { blogPostColOne, blogPostHead } from "../../TestData";
import MiniBlog from "./BlogPost/MiniBlog";
import SearchBar from "../../components/SearchBar/SearchBar";
import { getItems } from "../../core/QueryResponseProvider";
import { Link } from "react-router-dom";
import { Heading } from "../../components/Heading/Heading";
import Loader from "../../components/Loader/Loader";
import BlogPreview from "./BlogPreview/BlogPreview";

function Blog() {

    const [isLoading, setIsLoading] = useState(false);
    // const [params, setParams] = useState({});
    const [currentPosts, setCurrentPosts] = useState([]);
    const [postFeatured, setPostFeatured] = useState({});
    const [uniqueAuthors, setUniqueAuthors] = useState([]);
    const [uniqueTopics, setUniqueTopics] = useState([]);
    const [activeTab, setActiveTab] = useState(1);

    const [state, setState] = useState({
        query: "",
        orderBy: "",
        list: blogPostColOne,
    });

    useEffect(() => {
        document.title = 'educare - Blog';
        return () => {
            document.title = 'educare ';
        }
    },[])

    const getBlogPosts = () => {
        setIsLoading(true);
        const params  = {
            type: activeTab
        };
        // params.page = posts.current_page + 1;
        getItems('auth/v3/blog/index',params)
            .then(function (request: any) {
            setCurrentPosts(request.posts);
            if(request.postFeatured) {
                setPostFeatured(request.postFeatured);
                setUniqueAuthors(request.uniqueAuthors);
                setUniqueTopics(request.uniqueTopics);
            }
            setIsLoading(false);
        }, function (request: any) {
            // setIsLoading(false);
        });
    };

    useEffect(() => {
        getBlogPosts();
    }, [activeTab]);

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    return (
        <>
            <div className="col-md-12 blog-container home">
                <center>
                    {/*<Heading text="Blog"*/}
                    {/*    bg="#CEF0FF"*/}
                    {/*    color="#0098DA"*/}
                    {/*    boda="#CEF0FF" />*/}
                    <h1>Welcome to educare blog</h1>
                    <p>
                        Explore our extensive collection of blog articles covering various facets of education. We delve into topics such as effective strategies, valuable insights, advancements in educational technology, and much more.
                    </p>
                </center>
            </div>

            <div className="blog-tab">
                <div className="flexy offset-md-1 flexyM mt4 col-md-10">
                    <div className="tabs">
                        <button className={`tab offset-md-2 ${activeTab === 1 ? 'active2' : ''}`} onClick={() => handleTabClick(1)} >
                            Schools
                        </button>
                        <button className={`tab ${activeTab === 2 ? 'active2' : ''}`} style={{ marginLeft: '20px' }} onClick={() => handleTabClick(2)}  >
                            Businesses
                        </button>
                        <button className={`tab ${activeTab === 3 ? 'active2' : ''}`} style={{ marginLeft: '20px' }} onClick={() => handleTabClick(3)} >
                            News & Events
                        </button>
                    </div>
                </div>
                <div className="panels">
                    <div className={`panel active2`}>
                        {isLoading ? (
                            <Loader />
                        ) : (
                            <BlogPreview postFeatured={postFeatured} currentPosts={currentPosts} uniqueAuthors={uniqueAuthors} uniqueTopics={uniqueTopics}/>
                        )
                        }
                    </div>
                </div>



            </div>


        </>
    );
}

export default Blog;
