import React, { useEffect } from "react";
import { Heading } from "../../components/Heading/Heading";
import "./contact-page.scss";
import LinkedIn from "../../assets/images/Group9537.svg";
import FB from "../../assets/images/Group9538.svg";
import Twitter from "../../assets/images/Group17226.svg";
import YT from "../../assets/images/Vector1.svg";
import Insta from "../../assets/images/vuesax-bold-instagram.svg";
// import Twitter from "../../assets/images/twitter.svg";
import Form from "./Form/Form";
import { Prefooter } from "../../components/Prefooter/Prefooter";
import MasterForm from "../../components/FormWizard/MasterForm";
function ContactPage() {
  useEffect(() =>{
    document.title = 'educare - Contact Us';

    return() => {
        document.title = 'educare ';

    }
})
  return (
    <>
      <div className="col-md-12  flexy">
        <div className="col-md-6 address-div ">
          <div className="  next offset-md-2">
            {" "}
            <Heading
              text="Contact Us"
              bg="#CEF0FF"
              color="#0098DA"
              boda="#CEF0FF"
            />
            <div className="mb-5">
                  <h1 className="col-md-6">Stay Connected and Reach Out!</h1> <br />
                <h5>
                  We appreciate your visit! If you have any inquiries, comments, or
                  simply want to reach out, feel free to contact us. We value our
                  community and enjoy engaging with you.
                </h5>
                <h5>
                  Don’t hesitate to drop us a message or use the provided contact
                  form below. We’re here to assist you, connect with you, and
                  provide the support you need.
                </h5>{" "}
            </div>
          
            <div className="fill">
              <h2>Call us</h2>
              <p>Our friendly team is here to help</p>
           
            
              <h5>NIGERIA:</h5>
                  <a className="call" href="tel:+234 909 555 2183">+234 909 555 2183</a>
                  <br />
                  <a className="whatsapp" href="https://wa.me/2349043038325" target="_blank" rel="noreferrer">+234 904 303 8325</a>
                  <h6> </h6>
               
              <h5>UK:</h5>
                  <a className="call"  href="tel:+447932073610">+447932073610</a>
                  <br /> <br />
            </div>
            <div className="fill">
              <h4>Email Us</h4>
              <a className="mail" href="https://mail.google.com/mail/?view=cm&fs=1&to=support@educare.ng" target="_blank" rel="noreferrer" style={{ fontSize: '17px' }}>
                support@educare.ng
              </a> <br />
            </div>
              <br /><br />
            <div>
              <h4>Visit Us</h4>
              <p>Come say hello at our office</p> 
            </div>
            <div className="col-md-10 fill">
              {" "}
              <h6 className="address">
                UK: 20-23 Wenlock Road, London, England
                
              </h6>{" "}
              <br />
              <h6 className="address">
                U.S.A: 651, North Broad Street, Middletown, DE 19709, United
                States.
              </h6>{" "}
              <br />
              <h6 className="address">
                {" "}
                NIGERIA: 16B, Alhaji Hussein Sunmonu Street, Lekki Phase 1,
                Lagos State, Nigeria.
              </h6>
            </div>
            <ul className="list-unstyled list-inline mobile-ul media-icons">
               {/* <li className="list-inline-item">
                {" "}
                <a href="https://wa.me/2349043038325" target="_blank" rel="noreferrer">
                  <img className="whatsapp" src={WB} alt="WhatsApp Business" width="100%" />
                </a>
              </li> */}
              <li className="list-inline-item">
                {" "}
                <a
                  href="https://www.facebook.com/educareERP?mibextid=LQQJ4d"
                  target="_blank" rel="noreferrer"
                >
                  <img className="fb" src={FB} alt="facebook" width="100%" />

                </a>
              </li>
              <li className="list-inline-item">
                {" "}
                <a
                  href="https://twitter.com/educaretech?s=21&t=GEU4mJ3b4At6Rfj0mhZOmQ"
                  target="_blank" rel="noreferrer"
                >
                  {" "}
                  <img className="twi" src={Twitter} alt="twitter" width="100%" />
                </a>
               
              </li>
              <li className="list-inline-item">
                {" "}
                <img className="lI" src={Insta} alt="linkedin" width="100%" />
              </li>
              <li className="list-inline-item">
                {" "}
                <a
                  href="https://www.linkedin.com/company/educare-technology/"
                  target="_blank" rel="noreferrer"
                >
                  {" "}
                    <img className="lI" src={LinkedIn} alt="linkedin" width="100%" />
                </a>
              </li>
              <li className="list-inline-item">
                {" "}
                <a
                  href="https://www.youtube.com/@educareTech"
                  target="_blank" rel="noreferrer"
                >
                  {" "}
                    <img className="lI" src={YT} alt="youtube" width="100%" />
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="col-md-4 offset-md-1 mtt">
          {/* <Form text="Submit" bg="#0098DA" /> */}
          <MasterForm />
        </div>
      </div>
      <Prefooter text="Provide a Finance experience that will scale your business upward." />
    </>
  );
}

export default ContactPage;
