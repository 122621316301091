import React, { useState, useEffect } from 'react';
import "./cookiepolicy.scss";


interface CookiePolicyProps {
  onAccept: () => void;
}

const CookiePolicy: React.FC<CookiePolicyProps> = ({ onAccept }) => {

  const [accepted, setAccepted] = useState(false);

  useEffect(() => {
    const storedAccepted = localStorage.getItem('cookieAccepted');
    if (storedAccepted === 'true') {
      setAccepted(true);
    }
  }, []);

  const acceptCookiePolicy = () => {
    localStorage.setItem('cookieAccepted', 'true');
    setAccepted(true);
    onAccept();
  };

  if (accepted === true) {
    return null;
  }

  return (
    <div
      className={`cookie ${accepted ? 'hidden' : ''}`}
    >
      <p>
        This website uses cookies to ensure you get the best experience on our website.
      </p>
      <button
        onClick={acceptCookiePolicy}

      >Accept</button>
    </div>
  );
}

export default CookiePolicy;