import React, {useEffect} from "react";
import './dataProtection.scss'

function DataProtection() {

    useEffect(() => {
        document.title = 'educare - Data protection policy';

        return () => {
            document.title = 'educare ';

        }
    })
    return (
        <>
            <div className="terms-bg">
                <center>
                    <h1>Data Protection Policy for Educare</h1>
                </center>
            </div>
            <div className="data-protection offset-md-2 col-md-8">
                <h4>1. Introduction</h4>
                <p>Welcome to Educare's Data Protection Policy. At Educare, we are committed to ensuring the privacy and
                    security of your information. This policy outlines our practices and the measures we take to protect
                    your data.</p>
                <h4>2. Scope of Policy</h4>
                <p>This policy applies to all personal data processed by Educare's ERP solutions, including data
                    collected on our website, through our services, or through other channels related to our educational
                    or business services.</p>
                <h4>3. Data Collection</h4>
                <p>Educare collects information to provide and enhance our ERP solutions such as:
                    <ul>
                        <li>School administrative details</li>
                        <li>Student records</li>
                        <li>Parents records</li>
                        <li>Teacher profiles</li>
                        <li>Usage data</li>
                    </ul>
                    </p>
                <h4>4. Data Usage</h4>
                <p>Collected data is used to personalize the ERP experience, provide customer support, improve our
                    services, and comply with legal obligations.</p>
                <h4>5. Data Storage and Security</h4>
                <p>Data is stored securely on encrypted servers with restricted access. We regularly update our security
                    practices to guard against unauthorized access or data breaches.</p>
                <h4>6. Data Sharing and Transfer</h4>
                <p>Educare will not share personal data with third parties without consent, except where required by law
                    or to provide the service as outlined in this policy.</p>
                <h4>7. User Rights</h4>
                <p>Users have the right to access their data, request correction, object to processing, and seek
                    deletion where applicable.</p>
                <h4>8. Data Retention</h4>
                <p>We retain personal data for as long as necessary to provide our services, comply with legal
                    obligations, or as outlined in our agreements.</p>
                <h4>9. Compliance</h4>
                <p>Educare complies with the GDPR and other relevant data protection laws.
                </p>
                    <h4>10. Contact Information</h4>
                    <p>For any inquiries regarding our Data Protection Policy or practices, please contact our Data
                        Protection Officer at <a href="mailto:support@educare.ng">support@educare.ng</a>.</p>
                    <h4>11. Policy Updates</h4>
                    <p>This policy may be updated periodically, and we will notify users of significant changes through
                        our services or other means.</p>
                    <h4>12. Acceptance of Policy</h4>
                    <p>By using Educare's ERP solutions, you acknowledge that you have read this policy and agree to its
                        terms.</p>

            </div>

        </>
)
}

export default DataProtection
