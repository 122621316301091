import React from "react";
import "./heading.scss";
import { Link } from "react-router-dom";
export interface HeadingProps {
  bg: "string";
  color: "string";
  text: "string";
  boda: "string";
  display: "string";
  address:"string";
}
export const Heading = (props: HeadingProps) => {
  const { bg, color, text, boda, display,address } = props;
  return (
    <>
      <Link to={address}>
        <button
          style={{ backgroundColor: bg, color: color, border: boda }}
          className={`heading ${display}`}
        >
          {text}
        </button>
      </Link>




     

    </>
  );
};
