import React, { useState, useEffect } from 'react';
import './wordswapper.scss';

const WORDS = ['School', 'Business'];

function WordSwapper() {
  const [count, setCount] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCount((prevCount) => (prevCount + 1) % WORDS.length);
    }, 5000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <span className='cover-flip'>
      <span id='change' className={`word ${count === 0 ? 'flip-out' : 'flip-in'}`}>
        {WORDS[count]}
      </span>
    </span>
  );
}

export default WordSwapper;
