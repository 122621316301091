import MiniBlog from "../BlogPost/MiniBlog";
import {useEffect, useRef, useState} from "react";
import {Link} from "react-router-dom";
import {useNavigate} from "react-router";
import "./BlogPreview.scss";


function BlogPreview({ currentPosts,postFeatured,uniqueAuthors,uniqueTopics }) {
    const [filteredPosts, setFilteredPosts] = useState([]);
    const order = useRef();
    const topic = useRef();
    const author = useRef();
    const navigate = useNavigate();

    const handleChange = (e) => {
        filterPost();
    }
    const filterPost = () => {
        let tempPosts = [...currentPosts];
            tempPosts = tempPosts.filter((post) => {
                    if (author.current.value !== 'DEFAULT' && post.posted_by !== author.current.value) {
                        return false;
                    }

                    if (topic.current.value !== 'DEFAULT' && post.category_name !== topic.current.value) {
                        return false;
                    }
                    return true;
                })
                    .sort((postA, postB) => {
                        if (order.current.value === 'descending') {
                            return new Date(postA.created_at) - new Date(postB.created_at);
                        } else if (order.current.value === 'ascending') {
                            return new Date(postB.created_at) - new Date(postA.created_at);
                        } else {
                            return 0;
                        }
                    });
            setFilteredPosts(tempPosts);
    }
    const gotoBlog = (slug) => {
        navigate(`/blog/${slug}`);
    }

    useEffect(() => {
        setFilteredPosts(currentPosts);
    }, []);

    return (
        <>
            <div className="col-md-10 offset-md-1 gBlog" onClick={()=>gotoBlog(postFeatured.slug)}>
                <div className="row ">
                    <div className="wrapper" >
                        {postFeatured && (
                            <div className="card">
                                <div>
                                    <img src={postFeatured.thumbnail} alt="feature image" />
                                </div>
                                <div className="card__content">
                                    <div>
                                        <small>{postFeatured.estimated_time} Minutes Read</small>
                                    </div>
                                    <div className="feature">
                                                            <span>
                                                                Featured Post
                                                            </span>
                                        <hr />
                                        <span>
                                                                <small>{postFeatured.formattedPostDate}</small>
                                                            </span>
                                    </div>
                                    <h2>{postFeatured.title}</h2>

                                    <p>{postFeatured.displayBody}</p>
                                    <a className="blog-link">
                                        Learn More
                                    </a>
                                    <div className="card__actions">
                                        <div className="flexy">
                                            <img
                                                className="bloger-img "
                                                src={postFeatured.publishing_picture}
                                                alt="Author"
                                            />
                                            <div>
                                                <h6>{postFeatured.posted_by}</h6>
                                                <p className="bloger">{postFeatured.position} </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                    </div>

                </div>
            </div>
            {!!currentPosts?.length && (
            <div className="flexy col-md-10 offset-md-1 mt-2">
                <div className="col-md-5 flexy flexyM">
                    <h5>Filter by:</h5>
                    <div className="col-md-7 sort-container flexy flexyM">
                        <select name="order" ref={order} onChange={handleChange}>
                            <option value="DEFAULT" disabled="">Order</option>
                            <option value="ascending">Newest first</option>
                            <option value="descending">Oldest first</option>
                        </select>
                        <select name="author" ref={author} onChange={handleChange}>
                            <option value="DEFAULT" disabled="">Author</option>
                            {uniqueAuthors?.map((author,index) => (
                                <option key={index} value={author}>{author}</option>
                            ))

                            }
                        </select>
                        <select  name="topic" ref={topic} onChange={handleChange}>
                            <option value="DEFAULT">Topic</option>
                            {uniqueTopics?.map((topic,index) => (
                                <option key={index} value={topic} >{topic}</option>
                            ))}

                        </select>

                    </div>
                </div>
                {/* <div className="col-md-3 offset-md-4">
                                <form action="" className="flexy col-md-12">
                                    <div className="search-div col-md-12">
                                        <div className="search-bar  ">

                                            <button className="search-btn" type="submit">
                                            </button>
                                        </div>
                                    </div>
                                </form>
                                <ul>

                                </ul>
                            </div> */}
            </div>
            )}
            <div className="col-md-10 offset-md-1  mt2 ">
                <div className="change">
                    {filteredPosts && (
                        filteredPosts?.map((post) => (
                                <Link className="blog-post-container-outer" to={`/blog/${post.slug}`} key={post.slug}>
                                    <div className="blog-post-container">
                                        <div className="heder-img">
                                            <img className="img" src={post.thumbnail} alt="thumbnail" />
                                        </div>
                                        <div className="blog-post-container-inner">
                                            <p className="">{post.formattedPostDate}</p>
                                            <h5>{post.title}</h5>
                                            <p>{post.displayBody}</p>
                                            <div className="flexy">
                                                <img className="bloger-img" src={post.publishing_picture} alt="thumbnail" />
                                                <div>
                                                    <h6>{post.posted_by}</h6>
                                                    <p className="bloger">{post.position} </p>
                                                </div>
                                                <span className="readtime">
                                                                        <small>{post.estimated_time} Minutes Read</small>
                                                                    </span>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            ))
                    )

                    }

                </div>

            </div>
        </>
    );
}

export default BlogPreview;
