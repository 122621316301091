import React, { useEffect } from "react";
import './privacy.scss'
function Privacy() {

  useEffect(() => {
    document.title = 'educare - Privacy';

    return () => {
      document.title = 'educare ';

    }
  })
  return (
    <>
      <div className="terms-bg">
        <center>
          <h1>Privacy Policy</h1>
        </center>
      </div>
      <div className="terms-inner offset-md-2 col-md-8">
        <h1>Introduction</h1>
        <p>
          Thank you for choosing Educare Technology Limited ("Educare," "we," "our," or "us"). We are committed to safeguarding your privacy and protecting your personal information. This Privacy Policy outlines how we collect, use, and safeguard your data when you use our SAAS (Software As A Service) products, including "educare for School" and "educare for Business."
        </p>

        <h1>1. Information We Collect</h1>
        <p>
          We collect and process the following types of information:
        </p>
        <p>
          For each successive term, Company shall invoice Customer thirty (30) days ensuing to commencement of successive term. Customer will pay successive term invoices within Ten (10) business days following issuance. All payments must be made in Nigerian Naira.
        </p>

        <h4>1.1. Personal Information</h4>
        <p>
          When you sign up for our services, we may collect your name, email address, contact information, and billing details. For "educare for School," we may collect student and teacher data as provided by the educational institutions using our software.
        </p>

        <h4>1.2. Usage Data</h4>
        <p>
          We collect information about how you use our software, including log data, device information, and analytics.
        </p>

        <h4>1.3. Cookies and Tracking Technologies</h4>
        <p>
          We use cookies and similar technologies to enhance your user experience and gather information about your interactions with our website and services.
        </p>

        <h4>2. How We Use Your Information</h4>
        <p>
          We use your information for the following purposes:
        </p>

        <h4>2.1. Providing Services</h4>
        <p>
          To provide, maintain, and improve our software products. To communicate with you regarding updates, support, and other service-related matters.
        </p>

        <h4>2.2. Marketing and Communications</h4>
        <p>
          To send you promotional materials and updates about our products with your consent. To respond to your inquiries and requests.
        </p>

        <h4>2.3. Legal Compliance</h4>
        <p>
          To comply with applicable legal obligations, regulatory requirements, and to protect our legal interests.
        </p>

        <h4>3. Data Sharing</h4>
        <p>
          We may share your information with:
        </p>

        <h4>3.1. Service Providers</h4>
        <p>
          We may engage third-party service providers to assist with various aspects of our services.
        </p>

        <h4>3.2. Educational Institutions</h4>
        <p>
          For "educare for School," we may share student and teacher data with the respective educational institutions using our software.
        </p>

        <h4>3.3. Legal and Regulatory Authorities</h4>
        <p>
          We may disclose your information in response to legal requests or to comply with applicable laws and regulations.
        </p>

        <h4>4. Security</h4>
        <p>
          We take reasonable measures to protect your personal information from unauthorized access or disclosure.
        </p>

        <h4>5. Your Choices</h4>
        <p>
          You can control your information by:
        </p>
        <ul>
          <li>Updating your account settings.</li>
          <li>Opting out of marketing communications.</li>
          <li>Requesting access, correction, or deletion of your data.</li>
        </ul>

        <h4>6. International Data Transfers</h4>
        <p>
          Your data may be transferred and processed outside of Nigeria, but we will ensure that adequate data protection measures are in place.
        </p>

        <h4>7. Changes to this Privacy Policy</h4>
        <p>
          We may update this Privacy Policy to reflect changes in our practices. Please review it periodically.
        </p>

        <h4>8. Contact Us</h4>
        <p>
          If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at:
        </p>
        <p>
          Address: 16B, Alhaji Hussein Sunmonu Street, Lekki Phase 1, Lagos State, Nigeria
        </p>
        <a href="tel:+234 909 555 2183">+234 909 555 2183</a>
        <a mailto="support@educare.ng">support@educare.ng</a>
        <div className="col-md-8 offset-md-2 mt3">
          <h5>By using our services, you agree to the terms outlined in this Privacy Policy.</h5>
        </div>
      </div>

    </>
  )
}

export default Privacy
